@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
}
.formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .detailsUserDivGlobal{
    width: 100%;
    display: flex;
    flex-direction: column;
    .detailsUserDivconfig{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
      padding-bottom: 1%;
      margin-bottom: 16px;
      .detailsUserDiv_lbl1config{
        font-family: "SF Pro Display";
        font-size: 14px;
        font-weight: 500;
        color: RGBA(0, 0, 0, 0.85);
        margin-bottom: 3px;
      }
      .detailsUserDiv_lbl2config{
        font-family: "SF Pro Display";
        font-size: 14px;
        font-weight: 500;
        color: RGBA(0, 0, 0, 0.85);
        height: 40px;
        border: 1px solid RGB(217, 217, 217);
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 4px 11px;
        
      }
    }
  }
  .InputLablDiv {
    width: 100%;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
    }
  }


  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
      font-family: 'SF Pro Display' ;
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .ant-picker{
      width: 100%;
    }
  }
  .Breadcrumb{
    float: left;
    margin-bottom: 16px;
  }

  // .ant-form-item-required span {
  //   display: none;
  //   left: 0%;
  // }
  
  // .ant-form-item-required:before {
  //   display: none;
  //   left: 0%;
  // }

  .ant-form-item-label > 
label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
       content:none;
       //float: left;
    }


    .required{
      display: inline-block;
      margin-left: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
  }

  .contentcolumn{
    font-size: 14px;
    font-weight: 400;
    color: RGBA(0, 0, 0, 0.85);
    font-family: 'SF Pro Display' ;
    line-height: 1.571;
   
  }
  .titlecolumn{
    font-size: 14px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
    font-family: 'SF Pro Display' ;
    line-height: 1.571;
   
  }
  .div_rect{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    .rect_colonne{
      width: 17%;
      height: 40px !important;
      margin-right: 10%;
      .selectFilter{
        width: 100%;
        height: 40px !important;
        font-size: 16px;
        font-weight: 400;
        color: RGBA(0, 0, 0, 0.65);
      }
      .ant-select-selector{
        height: 40px !important;
        display: flex !important;
        align-items: center !important;
      }
      @media screen and (max-width: 1420px) {
        width: 20%;
      }
      @media screen and (max-width: 768px) {
        width: 30%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .rect_filter{
      width: 100%;
      height: 40px ;
      .inputFilter{
        height: 100%;
      }
      .rect_filter_search Input {
          width: 100%;
          height: 40px;}
        .rect_filter_search Button {
            width: 50px;
            height: 40px;
          }
      .filter_icon{
        width: 40px !important;
        height: 40px !important;
        padding: 5px; margin-right: -11px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.25);
        border-left: 1px solid #d9d9d9 !important;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
      .class_icon_filter{
        font-size: 16px;
        transition: all 0.3s ease-in-out;
      }
      .filter_icon:hover .class_icon_filter{
        color: #40a9ff;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .Filter{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;
    @media screen and (max-width: 1420px) {
      display: flex;
      flex-direction: column-reverse;
    }
  
    .delete_number{
      width: 17%;
      height: 40px;
      border-radius: 2px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      Button{
        margin-right: 5px;
        height: 40px;
        width: 40px;
      }
      p{
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        color: RGBA(0, 0, 0, 0.85);
      }
      @media screen and (max-width: 1420px) {
        width: 100%;
        margin-top: 10px;
      }
    }
    .div_rect{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
      .rect_colonne{
        width: 17%;
        height: 40px !important;
        margin-right: 10%;
        .selectFilter{
          width: 100%;
          height: 40px !important;
          font-size: 16px;
          font-weight: 400;
          color: RGBA(0, 0, 0, 0.65);
        }
        .ant-select-selector{
          height: 40px !important;
          display: flex !important;
          align-items: center !important;
        }
        @media screen and (max-width: 1420px) {
          width: 20%;
        }
        @media screen and (max-width: 768px) {
          width: 30%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 5px;
        }
      }
      .rect_filter{
        width: 100%;
        height: 40px ;
        .inputFilter{
          height: 100%;
        }
        .rect_filter_search Input {
            width: 100%;
            height: 40px;}
          .rect_filter_search Button {
              width: 50px;
              height: 40px;
            }
        .filter_icon{
          width: 40px !important;
          height: 40px !important;
          padding: 5px; margin-right: -11px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(0, 0, 0, 0.25);
          border-left: 1px solid #d9d9d9 !important;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
        .class_icon_filter{
          font-size: 16px;
          transition: all 0.3s ease-in-out;
        }
        .filter_icon:hover .class_icon_filter{
          color: #40a9ff;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  
  
  }