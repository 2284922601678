/**** font ***/
@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
}

/******* global ******/
@import "~antd/dist/antd.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.divGlobalTable_GrpRmBox {
  padding: 10px;

  .Header {
    .Breadcrumb {
      text-align: start;
    }
    .header_container_div{
      margin: 0 ;

    }
    .add_div{
        width: 100%;
        background-color: RGBA(0, 0, 0, 0.04);
        height: 58px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2%;
        margin-top: 15px;
        
        .btnCancel{
          margin-right: 10px;
        }
      }
      .div_btn_add_cancel{
        display: flex;
        flex-direction: row !important;
        .btnSubmit {
          .spn_add {
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
        }
        
        .btnCancel {
          .spn_cancel {
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
        }
      }
  }
  .Filter{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;
    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column-reverse;
    }
  
    .delete_number{
      width: 17%;
      height: 40px;
      border-radius: 2px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      Button{
        margin-right: 5px;
        height: 40px;
        width: 40px;
      }
      p{
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
        color: RGBA(0, 0, 0, 0.85);
      }
      @media screen and (max-width: 1420px) {
        width: 100%;
        margin-top: 10px;
      }
    }
    .div_rect{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
      .rect_colonne{
        width: 17%;
        height: 40px !important;
        margin-right: 10%;
        .selectFilter{
          width: 100%;
          height: 40px !important;
          font-size: 16px;
          font-weight: 400;
          color: RGBA(0, 0, 0, 0.65);
        }
        .ant-select-selector{
          height: 40px !important;
          display: flex !important;
          align-items: center !important;
        }
        @media screen and (max-width: 1420px) {
          width: 20%;
        }
        @media screen and (max-width: 768px) {
          width: 30%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          margin-bottom: 5px;
        }
      }
      .rect_filter{
        width: 100%;
        height: 40px ;
        .inputFilter{
          height: 100%;
        }
        .rect_filter_search Input {
            width: 100%;
            height: 40px;}
          .rect_filter_search Button {
              width: 50px;
              height: 40px;
            }
        .filter_icon{
          width: 40px !important;
          height: 40px !important;
          padding: 5px; margin-right: -11px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(0, 0, 0, 0.25);
          border-left: 1px solid #d9d9d9 !important;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
        }
        .class_icon_filter{
          font-size: 16px;
          transition: all 0.3s ease-in-out;
        }
        .filter_icon:hover .class_icon_filter{
          color: #40a9ff;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  
  
  }
  .addEditCard{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-inline: 12px;

    label {
      float: left !important;
      max-width: 100%;
      margin-block-start: 12px;
      margin-block-end: 5px;
      display: flex;
    }
    .label{
      width: 100%;
    }

  }
  .DataTable{
    height: 90vh!important;
    white-space: nowrap!important;
  }
  .dataTable {
    margin-top: 15px;
    overflow-y: scroll;
    height: fit-content;
    .ant-pagination-options {
      position: absolute;
      left: 0;
    }
    .ant-table-column-sorters{
      width: fit-content;
      margin: auto;
      .ant-table-column-sorter{
         margin-left: 12px;
       }
    }
    tbody {
      color: RGBA(0, 0, 0, 0.85);
    }
    .img_state {
      font-size: 21px;
    }
  }
  .dataTable::-webkit-scrollbar {
    width: 4px !important;
    height: 4px;
  }
  .dataTable::-webkit-scrollbar-track {
    background-color: var(--card_color) !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
  }
  .dataTable::-webkit-scrollbar-thumb {
    background-color: var(--icon-color); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
  
 

  .action{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline-end: 5px;
    svg{
      font-size: 18px;
      fill: RGBA(0, 0, 0, 0.65);
    }
    div{
      font-size: 15px;
      .icon_action{
        cursor: pointer;
      }
    }
    

    @media screen and (max-width: 768px) {
      //width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    @media screen and (max-width:400px){
      flex-direction: column;
    }
  }
}


.tableTextAligne{
 // border:1px solid red;
  width:100%; 
  margin:auto;
  text-align:center;
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-table-thead{
.ant-table-column-sorters{
margin-left: 2px !important;
}
}
///Modal


    .detailsGroupeRmBoxDivGlobal{
      width: 100%;
      display: flex;
      flex-direction: column;
      
      .detailsGroupeRmBoxDiv{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
        padding-bottom: 1%;
        margin-bottom: 16px !important;
    

      
      }
    }
  
//********* responsive table *********//

.DataTable{
    @media screen and (max-width: 768px) {
      table {
        border: 0;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
        
      }
      tr {
        width: 100%;
        border: 1px solid #ddd;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: .625em;
      }
      td {
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      td:last-child {
        border-bottom: 0;
        display: block;
      }
    }
    @media screen and (max-width: 767px) {
      table {
        border: 0;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      tr {
        border: 1px solid #ddd;
        display: block;
        margin-bottom: .625em;
      }
      td {
        border-bottom: 1px solid #ddd;
        display: flex;
        text-align: left;
      }
      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }
      td:last-child {
        border-bottom: 0;
        display: block;
        background-color: #ddd;
      }
  
    }
  }
  .ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    font-weight: 500 !important;
    
      }

      .profile_title {
        font-size: 20px !important;
        font-weight: 500;
        color: RGBA(0, 0, 0, 0.85);
        
        @media screen and (max-width: 520px) {
          font-size: 17px !important;
        }
        
        @media screen and (max-width: 490px) {
          // inline-size:100px; 
          font-size: 14px !important;
          white-space:normal;
          word-wrap: break-word;
        
        }
        @media screen and (max-width:380px){
          inline-size:50px; 
          font-size: 14px !important;
          white-space:normal;
          word-wrap: break-word;
          width:100%; 
          margin:auto;
          text-align:center;
          overflow-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      
    .add_cancel_div {
      display: flex;
      flex-direction: row;
      gap: 0.6em;
      @media screen and (max-width:480px){
        &>label{
          font-size: 12px;
          padding: 5px;
        }
      }
      @media screen and (max-width:450px){
        &>label{
          font-size: 10px;
        }
      }
  }
  .errorMsg{
    color:#ff3333;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    padding-block-start: 5px;
    }
      
.modal h2{ 
  margin-bottom: 14px !important; 
}