@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/ping-fang-sc/PingFang\ SC\ Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/ping-fang-sc/PingFang\ SC\ Regular.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/ping-fang-sc/PingFangSCMedium.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
}

.formItem1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .InputLablDiv {
    width: 100%;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
    }
  }


  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
      font-family: 'SF Pro Display' ;
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .Breadcrumb{
    float: left;
    margin-bottom: 16px;
  }



  /********************************************/

  @import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

//// add divvv
.add_div{
  width: 100%;
  background-color: RGBA(0, 0, 0, 0.04);
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  margin-top: 15px;
  .profile_title{
    font-size: 20px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
  }
  .btnCancel{
    margin-right: 10px;
  }
}
.Filter{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
  @media screen and (max-width: 820px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .delete_number{
    width: 17%;
    height: 40px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    Button{
      margin-right: 5px;
      height: 40px;
      width: 40px;
    }
    p{
      margin-top: 15px;
      padding-inline: 5px;
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
    }
    // @media screen and (max-width: 1420px) {
    //   width: 100%;
    //   margin-top: 10px;
    // }
  }
  .div_rect{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
    .rect_colonne{
      width: 17%;
      height: 40px !important;
      margin-right: 10%;
      .selectFilter{
        width: 100%;
        height: 40px !important;
        font-size: 16px;
        font-weight: 400;
        color: RGBA(0, 0, 0, 0.65);
      }
      .ant-select-selector{
        height: 40px !important;
        display: flex !important;
        align-items: center !important;
      }
      @media screen and (max-width: 1420px) {
        width: 20%;
      }
      @media screen and (max-width: 768px) {
        width: 30%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }
    .rect_filter{
      width: 100%;
      height: 40px;
      .inputFilter{
        height: 100%;
      }
      .filter_icon{
        width: 40px !important;
        height: 40px !important;
        padding: 5px; margin-right: -11px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.25);
        border-left: 1px solid #d9d9d9 !important;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
      }
      .class_icon_filter{
        font-size: 16px;
        transition: all 0.3s ease-in-out;
      }
      .filter_icon:hover .class_icon_filter{
        color: #40a9ff;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }


}
@media screen and (max-width: 900px){
  .Filter .delete_number{
    width: 17%;
    height: 40px;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}
.divGlobalTable{
  padding: 12px 16px;
  .DataTable{
    height: 80vh!important;
    white-space: nowrap!important;
  }
  .dataTable{
    margin-top: 15px;
    overflow-y: scroll;
    height: 80vh !important;
  }
  .dataTable::-webkit-scrollbar{
    width: 4px !important;
    height: 4px;
  }
  .dataTable::-webkit-scrollbar-track{
    background-color: var(--card_color) !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
  }
  .dataTable::-webkit-scrollbar-thumb{
    background-color: var(--icon-color);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }
}
.action{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div{
    font-size: 15px;
    .icon_action{
      cursor: pointer;
      margin-inline: 5px;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.64);
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
/*******************************/
.detailsUserDivGlobal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .detailsUserDiv{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
    padding-bottom: 1%;
    margin-bottom: 30px;
    .detailsUserDiv_spn1{
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.45);
    }
    .detailsUserDiv_spn2{
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
    }
  }
}
/**********Configuration serveur**********/
.detailsUserDivGlobal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .detailsUserDivserveur{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1%;
    margin-bottom: 16px;
    .detailsUserDiv_spn1serveur{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
    }
    .detailsUserDiv_spn2serveur{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
    }
  }
}

.detailsUserDivGlobal{
  width: 100%;
  display: flex;
  flex-direction: column;
  .detailsUserDivconfig{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1%;
    margin-bottom: 16px;
    .detailsUserDiv_lbl1config{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      margin-bottom: 3px;
    }
    .detailsUserDiv_lbl2config{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      height: 40px;
      border: 1px solid RGB(217, 217, 217);
      border-radius: 2px;
      display: flex;
      align-items: center;
      padding: 4px 11px;
      
    }
  }
  .detailsUserDivconfiglast{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1%;
    margin-bottom: 0px;
    .detailsUserDiv_lbl1config{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      margin-bottom: 3px;
    }
    .detailsUserDiv_lbl2config{
      font-family: "SF Pro Display";
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      height: 40px;
      border: 1px solid RGB(217, 217, 217);
      border-radius: 2px;
      display: flex;
      align-items: center;
      padding: 4px 11px;
      
    }
  }
}
/***********************************/
.div_btn_add_cancel{
  display: flex;
  flex-direction: row !important;
}

.btnSubmit {
  .spn_add {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.btnCancel {
  .spn_cancel {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}



//********* responsive table *********//

.DataTable{
  @media screen and (max-width: 768px) {
    table {
      border: 0;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      width: 100%;
      border: 1px solid #ddd;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: .625em;
    }
    td {
      border-bottom: 1px solid #ddd;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    td:last-child {
      border-bottom: 0;
      display: block;
    }
  }
  @media screen and (max-width: 767px) {
    table {
      border: 0;
    }
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    tr {
      border: 1px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    td {
      border-bottom: 1px solid #ddd;
      display: flex;
      text-align: left;
    }
    td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    td:last-child {
      border-bottom: 0;
      display: block;
      background-color: #ddd;
    }

  }
}


.ant-pagination-options{
  position: absolute;
  left: 0;
}




/***************************************************************************/

.addedititem{
margin-top: 2%;
}


.ant-form-item-label > 
label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before{
       content:none;
    }
.required{
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.selectstyle{
  width: 100%;
  text-align: start;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnSeparator {

  visibility: hidden;

}
.modal input{
  margin-bottom: 12px;
  padding: 12px;
}

@media screen and (max-width:800px){
  .addZoneClass{
    display: block;
    .addZoneCol{
      max-width: 100%;
    }
  }
}

@media screen and (max-width:400px) {
  .add_div .profile_title{
    font-size: 16px;
  }
} 