@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
}

.formItem1 {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.85);
  
      .requiredLabel {
        color: #F90001;
      }
    }
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .detailsUserDivGlobal{
    width: 100%;
    display: flex;
    flex-direction: column;
    .detailsUserDivconfig{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
      padding-bottom: 1%;
      margin-bottom: 16px;
      .detailsUserDiv_lbl1config{
        font-family: "SF Pro Display";
        font-size: 14px;
        font-weight: 500;
        color: RGBA(0, 0, 0, 0.85);
        margin-bottom: 3px;
      }
      .detailsUserDiv_lbl2config{
        font-family: "SF Pro Display";
        font-size: 14px;
        font-weight: 500;
        color: RGBA(0, 0, 0, 0.85);
        height: 40px;
        border: 1px solid RGB(217, 217, 217);
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 4px 11px;
        
      }
    }
    .detailsUserDivconfiglast{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //border-bottom: 1px solid RGBA(0, 0, 0, 0.06);
      padding-bottom: 1%;
      margin-bottom: 0px;
      .detailsUserDiv_lbl1config{
        font-family: "SF Pro Display";
        font-size: 14px;
        font-weight: 500;
        color: RGBA(0, 0, 0, 0.85);
        margin-bottom: 3px;
      }
      .detailsUserDiv_lbl2config{
        font-family: "SF Pro Display";
        font-size: 14px;
        font-weight: 500;
        color: RGBA(0, 0, 0, 0.85);
        height: 40px;
        border: 1px solid RGB(217, 217, 217);
        border-radius: 2px;
        display: flex;
        align-items: center;
        padding: 4px 11px;
        
      }
    }
  }
  .InputLablDiv {
    width: 100%;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 10px;
    }
  }


  .formItem3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    label {
      font-size: 14px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      font-family: 'SF Pro Display' ;
      font-style: normal;
      line-height: 1.571;
      .requiredLabel {
        color: #F90001;
      }
    }
  
    .ant-col-8 {
      max-width: 100% !important;
    }
  
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .Breadcrumb{
    float: left;
    margin-bottom: 16px;
  }

 .editmodaltitle .ant-modal-header{
    background-color: #1890ff;
  }

  .titlemodal{
    font-size: 18px;
    font-weight: 700;
    
    font-family: 'SF Pro Display' ;
    color: white;
  }

  .grilletitle{
    font-size: 14px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
    font-family: 'SF Pro Display' ;
    margin-bottom: 12px;
    z-index: 6;
   
  }
  .contentcolumn{
    font-size: 14px;
    font-weight: 400;
    color: RGBA(0, 0, 0, 0.85);
    font-family: 'SF Pro Display' ;
    line-height: 1.571;
   
  }
  .titlecolumn{
    font-size: 14px;
    font-weight: 500;
    color: RGBA(0, 0, 0, 0.85);
    font-family: 'SF Pro Display' ;
    line-height: 1.571;
    text-align: start;
   
  }

  .add_div{
    width: 100%;
    background-color: RGBA(0, 0, 0, 0.04);
    height: 58px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    margin-top: 15px;
    .profile_title{
      font-family: 'SF Pro Display' ;
      font-size: 20px;
      font-weight: 500;
      color: RGBA(0, 0, 0, 0.85);
      line-height: 1.4;
    }
    .btnCancel{
      margin-right: 10px;
    }
  }

  .btnCancel {
    .spn_cancel {
      font-family: 'SF Pro Display' ;
      font-size: 14px;
      font-weight: 400;
      color: RGBA(0, 0, 0, 0.65);
      line-height: 1.571;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  .iconcolor{
    color:RGBA(0, 0, 0, 0.65) ;
  }

  .selectstyle{
    width: 100%;
    text-align: start;
  }
 
 .adressClass{
  display: flex;
  flex-direction: column;
  align-items: baseline;
 }
 .icons-space{
  display: flex;
max-width: 100%;
  flex-wrap: wrap;
  .icon_action{
  cursor: pointer;
  margin-inline: 5px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.64);
}
 }

.divGlobalTable{
  padding: 12px 16px;
  .DataTable{
    height: 80vh!important;
    white-space: nowrap!important;
  }
  .dataTable{
    margin-top: 15px;
    overflow-y: scroll;
    height: 80vh !important;
  }
  .dataTable::-webkit-scrollbar{
    width: 4px !important;
    height: 4px;
  }
  .dataTable::-webkit-scrollbar-track{
    background-color: var(--card_color) !important;
    z-index: 999;
    border: none !important;
    box-shadow: none !important;
  }
  .dataTable::-webkit-scrollbar-thumb{
    background-color: var(--icon-color);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
  }
}