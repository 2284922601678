@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/ping-fang-sc/PingFang\ SC\ Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/ping-fang-sc/PingFang\ SC\ Regular.otf') format("opentype");
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/ping-fang-sc/PingFangSCMedium.otf') format("opentype");
    font-weight: 500;
    font-style: normal;
  }
  .ant-form-item-label > label{
    font-weight: 500 !important;
  } 

  .pointer{
    cursor: pointer ;
  }
  .ant-table-thead > tr > th {
    text-align: center !important;
  }
  .arrowleft{
    color: #000000;
    margin-right: 14px;
    font-size: 18px;
    }
    